<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
      <v-spacer></v-spacer>
    </v-layout>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
        <v-text-field
          v-model="search"
          dense
          solo
          :label="$t('search expense claims')"
        ></v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              color="white"
              @click="getExpenseClaims()"
              ><v-icon>mdi-refresh</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("refresh table") }}</span>
        </v-tooltip>
      </v-flex>
      <v-flex xs12 sm4 md2 lg2 class="text-right pa-1">
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="dateTime"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateTime"
              append-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              in
              solo
            ></v-text-field>
          </template>
          <v-date-picker v-model="dateTime" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.dialog.save(dateTime);
                dateFilter();
              "
            >
              {{ $t("ok") }}
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center v-if="employees">
      <v-flex xs12 sm6 md6 lg6 class="text-left">
        <v-avatar size="40">
          <v-img :src="employees.avatar" style="cursor: pointer">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  width="5"
                  indeterminate
                  :color="$store.state.primaryColor"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-avatar>
        <span class="text-h5 font-weight-bold">
          {{
            employees.names.first +
            " " +
            employees.names.middle +
            " " +
            employees.names.last
          }}</span
        >
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs12 sm4 md4 lg4 class="py-2 text-right">
        <v-btn
          :color="$store.state.primaryColor"
          outlined
          small
          @click="newExpenseClaimEmployees(employeeId)"
        >
          {{ $t("new expense claim") }}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs12 sm12 md12 lg12>
        <v-data-table
          :headers="headers"
          :loading="loading"
          :items="filteredExpenseClaims"
          :search="search"
          :sort-by.sync="sortByDate"
          :sort-desc.sync="sortDesc"
          :items-per-page="20"
        >
          <template v-slot:[`item.businessId`]="{ item }">
            {{ getBusinessName(item.businessId) }}
          </template>
          <template v-slot:[`item.dateTime`]="{ item }">
            {{ formatDate(item.dateTime) }}
          </template>
          <template v-slot:[`item.amount`]="{ item }">
            <v-btn small text dark left :color="$store.state.primaryColor">
              {{ currencyFormat(item.amount) }}</v-btn
            >
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <v-icon small left :color="$store.state.primaryColor"
              >mdi-pencil</v-icon
            >
            <v-icon
              small
              left
              :color="$store.state.secondaryColor"
              @click="viewExpenseClaimDetails(item.id)"
              >visibility</v-icon
            >
            <v-icon small left color="red">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import db from "@/plugins/fb";
import { format } from "date-fns";
export default {
  data: () => ({
    loading: true,
    search: "",
    modal: false,
    sortDesc: true,
    sortByDate: "dateTime",
    filteredExpenseClaims: [],
    expenseClaims: [],

    employees: [],
    dateTime: format(new Date(), "yyyy-MM-dd"),
  }),

  created() {
    this.getExpenseClaims();
    this.getEmployeeDetails();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("business"),
          value: "businessId",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("amount"),
          value: "amount",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("date"),
          value: "dateTime",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },

        {
          text: this.$t("description"),
          value: "description",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
        },
        {
          text: this.$t("action"),
          value: "action",
          class: "blue-grey darken-3 white--text font-weight-regular",
          sortable: false,
          filterable: false,
        },
      ];
    },
  },

  methods: {
    fullName(obj) {
      for (let x in obj) {
        return obj[x] + " ";
      }
    },

    getEmployeeDetails() {
      db.collection("employees")
        .doc(this.$route.params.id)
        .get()
        .then((doc) => {
          this.employees = doc.data();

          this.employeeId = this.employees.employeeId;
          this.employees.names.first;
          this.employees.names.middle;
          this.employees.names.last;
          this.employees.avatar;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getExpenseClaims() {
      this.loading = true;
      this.expenseClaims = [];

      db.collection("expenseClaims")
        .where("employeeId", "==", this.$route.params.id)

        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.expenseClaims.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.filteredExpenseClaims = this.expenseClaims;
          console.log(this.expenseClaims);

          this.loading = false;
        });
    },

    getBusinessName(id) {
      let busId = this.$store.state.businesses.find((item) => item.id == id);
      let busName = busId.businessName;
      return busName;
    },
    dateFilter() {
      let filtered = this.expenseClaims.filter(
        (item) => format(item.dateTime.toDate(), "yyyy-MM-dd") == this.dateTime
      );
      this.filteredExpenseClaims = filtered;
    },
    viewExpenseClaimDetails(id) {
      this.$router.push({ name: "ViewExpenseClaims", params: { id: id } });
    },

    newExpenseClaimEmployees(id) {
      this.$router.push({ name: "CreateExpenseClaims-2", params: { id: id } });
    },
    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + val;
    },

    formatDate(val) {
      return format(val.toDate(), "dd MMMM, yyyy HH:mm:a");
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
